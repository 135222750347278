import React, { useEffect, useState } from "react";
import TopBarIndex from "../components/TopBarIndex";
import FooterDown from "../Sezioni/FooterDown";
import { api } from "../conf/apiConf";
import FooterTop from "../Sezioni/FooterTop";
import InputGimlet from "../components/InputGimlet";
import ButtonCustom from "../components/ButtonCustom";
import RowHeaders from "../components/RowHeaders";
import DialogCustom from "../components/DialogCustom";
import InputCustom from "../components/InputCustom";
import { Alert, FormControlLabel, MenuItem, Snackbar, Switch } from "@mui/material";
import SnackbarComponent from "../components/SnackbarComponent";
import InputBilo from "../components/InputBilo";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { connect } from "react-redux";
import useSWR from "swr";
import LoadingComponent from "../components/LoadingComponent";
import ErrorsComponent from "../components/ErrorsComponent";
import env from "../conf/variables";

const FormModalRimborso = ({ errors, setErrors, importRichiesto, setImportRichiesto, iban, setIban, intestatario, setIntestatario }) => {
    // console.log(iban, intestatario)
    return (
        <div>
            <div className="flex flex-row items-center gap-6">
                <InputCustom
                    title={"Importo richiesto*"}
                    value={importRichiesto}
                    fullWidth
                    // InputProps={{
                    //   inputProps: {
                    //     max: SALDO ATTUALE,
                    //      min: 0
                    //   },
                    // }}
                    type="number"
                    onChange={(e) => setImportRichiesto(e.target.value)}
                />
                <InputCustom
                    fullWidth
                    title={"IBAN*"}
                    value={iban}
                    onChange={(e) => setIban(e.target.value)}
                />
                <InputCustom
                    fullWidth
                    title={"Intestatario*"}
                    value={intestatario}
                    onChange={(e) => setIntestatario(e.target.value)}
                />
            </div>

            {
                errors && errors.length ?
                    errors?.map((error, index) => {
                        return (
                            <Alert
                                className="!my-4 !mx-2"
                                variant={"filled"}
                                onClose={() => setErrors(false)}
                                severity={'error'}
                                sx={{ width: "100%" }}>
                                {error}
                            </Alert>
                        )
                    })
                    :
                    ''
            }
        </div>

    );
};

const BorsellinoElettronico = ({ count }) => {

    useEffect(() => {
        document.getElementById('main_title').innerText = 'Borsellino elettronico - Trekking Italia'
    }, [])

    const [openModal, setOpenModal] = useState(false);
    const [openModalRimborso, setOpenModalRimborso] = useState(false);
    const [importWish, setImportWish] = useState(null);
    const [importRichiesto, setImportRichiesto] = useState(null);
    const [iban, setIban] = useState(count?.profile?.iban);
    const [intestatario, setIntestatario] = useState(count?.profile?.accountholder);
    const [paymentMethod, setPaymentMethod] = useState(null);
    const [openSuccess, setOpenSuccess] = useState(false);
    const [activeBorsellino, setActiveBorsellino] = useState(false);
    const [openModalTerms, setOpenModalTerms] = useState(false);
    const [openModalAlert, setOpenModalAlert] = useState(false);
    const [activePagamenti, setActivePagamenti] = useState(true);
    const [activeRimborsi, setActiveRimborsi] = useState(false);
    const [quotaRimborsata, setQuotaRimborsata] = useState(null);
    const [saldo, setSaldo] = useState(0);
    const [reload, setReload] = useState(false)
    const [errors, setErrors] = useState([])
    const [isIphone, setIsIphone] = useState(false)
    const [linkForIphone, setLinkForIphone] = useState(null)

    api.setHeaders({
        Authorization: "Bearer " + count.token,
    });

    // const fetcher = (url) => api.get(url).then((res) => res);
    // const { data: payment, error: errorPayment } = useSWR(
    //   [`/users/get-payment-method`],
    //   fetcher
    // );

    const fetcher4 = (url) =>
        api.get(url).then((res) => {
            setActiveBorsellino(res.data.data.borsellino ? true : false);
            setActivePagamenti(res.data.data.utilizzaPagamenti ? true : false);
            setActiveRimborsi(res.data.data.versamentoRimborsi ? true : false);
            setSaldo(Number(res.data.data.saldo) / 100);
            return res;
        });
    const { data: borsellino, error: errorBorsellino } = useSWR(
        [`/users/` + count.profile.id + "/borsellino", reload],
        fetcher4
    );

    const fetcher2 = (url) =>
        api.get(url).then((res) => {
            setQuotaRimborsata(res.data.data.wish_balance);
            return res;
        });
    const { data: user, error: errorUser } = useSWR(
        [`users/get/` + count.profile.id],
        fetcher2
    );

    const fetcher3 = (url) =>
        api.get(url).then((res) => {
            return res;
        });
    const { data: storico, error: errorStorico } = useSWR(
        [`users/` + count.profile.id + "/borsellino/storico", reload],
        fetcher3
    );

    const FormModalCarica = () => {
        return (
            <div className="flex flex-row items-center gap-6">
                <InputCustom
                    title={"Importo desiderato*"}
                    value={importWish}
                    fullWidth
                    type="number"
                    onChange={(e) => setImportWish(e.target.value)}
                />
                <InputCustom
                    select
                    fullWidth
                    title={"Metodi di pagamento*"}
                    value={paymentMethod}
                    onChange={(e) => setPaymentMethod(e.target.value)}>
                    <MenuItem value={"carta"} key={0}>
                        Carta di Credito
                    </MenuItem>
                </InputCustom>
            </div>
        );
    };

    // const FormModalRimborso = () => {

    //     return (
    //         <div className="flex flex-row items-center gap-6">
    //             <InputCustom
    //                 title={"Importo richiesto*"}
    //                 value={importRichiesto}
    //                 fullWidth
    //                 // InputProps={{
    //                 //   inputProps: {
    //                 //     max: SALDO ATTUALE,
    //                 //      min: 0
    //                 //   },
    //                 // }}
    //                 type="number"
    //                 onChange={(e) => setImportRichiesto(e.target.value)}
    //             />
    //             <InputCustom
    //                 fullWidth
    //                 title={"IBAN*"}
    //                 value={iban}
    //                 onChange={(e) => setIban(e.target.value)}
    //             />
    //             <InputCustom
    //                 fullWidth
    //                 title={"Intestatario*"}
    //                 value={intestatario}
    //                 onChange={(e) => setIntestatario(e.target.value)}
    //             />
    //         </div>
    //     );
    // };

    const CondizioniTermini = () => {
        return (
            <iframe
                title="Informativa Privacy"
                className="w-full"
                height="350"
                src={`${env.url_api_without_api}document/borsellino_elettronico.pdf`}
                allowFullScreen
            ></iframe>
        );
    };

    const columns = [
        {
            field: "data",
            sortable: false,
            headerName: "Data e ora",
            flex: 1,
            renderCell: (params) => {
                return (
                    <div className="cursor-pointer">
                        {params.value + " " + params.row.ora}
                    </div>
                );
            },
        },
        {
            field: "tipo_di_operazione",
            sortable: false,
            headerName: "Tipo di operazione",
            flex: 1,
            renderCell: (params) => {
                return <div className="cursor-pointer">{params.value}</div>;
            },
        },
        {
            field: "descrizione",
            sortable: false,
            headerName: "Descrizione",
            flex: 1,
            renderCell: (params) => {
                return <div className="cursor-pointer">{params.value}</div>;
            },
        },
        {
            field: "importo",
            sortable: false,
            headerName: "Importo",
            flex: 1,
            renderCell: (params) => {
                return <div className="cursor-pointer">{params.value}</div>;
            },
        },
        {
            field: "confirmed",
            sortable: false,
            headerName: "Stato",
            flex: 1,
            renderCell: (params) => {
                return <div className="cursor-pointer">{params.value ? 'Confermato' : 'In corso'}</div>;
            },
        }
    ];
    if (errorBorsellino || errorUser || errorStorico) return <ErrorsComponent />;
    if (!borsellino || !user || !storico) return <LoadingComponent />;

    return (
        <div>
            <TopBarIndex />
            <div className="pt-12 container mx-auto relative">
                <a className="text-lg absolute top-2 text-red-500 right-4" target="_blank" href={`${env.url_api_without_api}document/utilizzo_borsellino_elettronico.pdf`}>Guida all'utilizzo</a>
                <div className="flex flex-col md:flex-row items-center w-fuoll justify-between">
                    <RowHeaders
                        className="w-full"
                        campo="Saldo attuale borsellino"
                        data={saldo + "€"}
                    />
                    {
                        activeBorsellino &&
                        <div className={"flex flex-col md:flex-row w-full items-center gap-8 justify-center mt-8 sm:mt-0"}>
                            <ButtonCustom className="max-w-fit" onClickCustom={() => setOpenModal(true)}>
                                <InputGimlet title="CARICA BORSELLINO" />
                            </ButtonCustom>
                            {/* MOSTRA SOLO SE SALDO è MAGGIORE DI 0 */}
                            {saldo > 0 &&
                                <ButtonCustom className="max-w-fit" onClickCustom={() => setOpenModalRimborso(true)}>
                                    <InputGimlet title="RICHIEDI RIMBORSO" />
                                </ButtonCustom>}
                        </div>
                    }
                </div>
                <div className="mt-8">
                    <FormControlLabel
                        control={
                            <Switch
                                checked={activeBorsellino}
                                onChange={(e) => {
                                    // SE SALDO è MAGGIORE DI 0 NON è POSSIBILE DIATTIVARE  E APRIRE MODAL setOpenModalAlert
                                    if (activeBorsellino) {
                                        if (saldo == 0) {
                                            api
                                                .post("/users/borsellino/enable-column", {
                                                    id_user: count.profile.id,
                                                    column: "enabled_e_wallet",
                                                    enable: 0,
                                                })
                                                .then((res) => {
                                                    setActiveBorsellino(false);
                                                    setOpenSuccess(true);
                                                })
                                                .catch((err) => console.log(err));
                                        } else {
                                            setOpenModalAlert(true);
                                        }
                                    } else {
                                        setOpenModalTerms(true);
                                    }
                                }}
                            />
                        }
                        label={
                            activeBorsellino ? "Disattiva borsellino" : "Attiva borsellino"
                        }
                    />
                </div>
                <div className="my-12 overflow-y-auto">
                    <InputBilo
                        className={"text-xl font-bold"}
                        title="Termini e Condizioni d’uso"
                    />
                    {CondizioniTermini()}
                </div>
                {
                    activeBorsellino &&
                    <div className="flex flex-col gap-4">
                        <div>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={activePagamenti}
                                        onChange={(e) => {
                                            setActivePagamenti(e.target.checked);
                                            api
                                                .post("/users/borsellino/enable-column", {
                                                    id_user: count.profile.id,
                                                    column: "enabled_usage_for_payment",
                                                    enable: e.target.checked ? 1 : 0,
                                                })
                                                .then((res) => {
                                                    setOpenSuccess(true);
                                                })
                                                .catch((err) => console.log(err));
                                        }}
                                    />
                                }
                                label={"Abilita utilizzo per pagamenti"}
                            />
                        </div>
                        <div>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={activeRimborsi}
                                        onChange={(e) => {
                                            setActiveRimborsi(e.target.checked);
                                            api
                                                .post("/users/borsellino/enable-column", {
                                                    id_user: count.profile.id,
                                                    column: "enabled_usage_for_refunded",
                                                    enable: e.target.checked ? 1 : 0,
                                                })
                                                .then((res) => {
                                                    setOpenSuccess(true);
                                                })
                                                .catch((err) => console.log(err));
                                        }}
                                    />
                                }
                                label={"Abilita rimborsi su borsellino"}
                            />
                        </div>
                        {activeRimborsi && (
                            <div className="flex flex-col lg:flex-row items-center gap-8 w-full lg:w-1/2">
                                <InputCustom
                                    type="number"
                                    title={"Importo massimo Singolo quota rimborsata*"}
                                    value={quotaRimborsata}
                                    onChange={(e) => setQuotaRimborsata(e.target.value)}
                                />
                                <ButtonCustom
                                    className={"w-full mt-4"}
                                    onClickCustom={() => {
                                        api
                                            .post("/users/borsellino/enable-column", {
                                                id_user: count.profile.id,
                                                quota: quotaRimborsata,
                                                column: "enabled_usage_for_refunded",
                                                enable: 1,
                                            })
                                            .then((res) => {
                                                setOpenSuccess(true);
                                            })
                                            .catch((err) => console.log(err));
                                    }}>
                                    <InputGimlet title="SALVA" />
                                </ButtonCustom>
                            </div>
                        )}
                    </div>
                }
                <div className="mt-12 mb-[150px]" style={{ height: "90vh", maxHeight: "500px", width: "100%" }}>
                    <DataGrid
                        rows={storico ? storico.data.data : []}
                        columns={columns}
                        autoPageSize={true}
                        getRowHeight={() => "auto"}
                        sx={{
                            [`& .${gridClasses.cell}`]: {
                                py: 1,
                            },
                        }}
                        isRowSelectable={() => false}
                        disableColumnMenu={true}
                        pagination
                    />
                </div>
            </div>
            {/* MODALE CARICA*/}
            <DialogCustom
                title={"Carica borsellino"}
                fullScreen={false}
                open={openModal}
                handleClose={() => {
                    localStorage.setItem("lastpage", window.location.pathname);
                    api
                        .post("/users/" + count.profile.id + "/borsellino/prepareWallet", {
                            user_id: count.profile.id,
                            balance: importWish,
                            paymentMethod: paymentMethod,
                        })
                        .then((res) => {
                            const userAgent = navigator.userAgent || navigator.vendor || window.opera;

                            // Controllo per Iphone
                            if (/iPad|iPhone|iPod|Mac/.test(userAgent) && !window.MSStream) {
                                setIsIphone(true)
                                setLinkForIphone(res.data.data)
                            } else {
                                let a = document.createElement("a");
                                document.body.appendChild(a);
                                a.style = "display: none";
                                a.href = res.data.data;
                                a.target = '_blank'
                                a.click();
                                document.body.removeChild(a);
                                setOpenModal(false);
                            }
                        })
                        .catch((err) => console.log(err));
                }}
                handleClose2={() => {
                    localStorage.setItem("lastpage", window.location.pathname);
                    setOpenModal(false)
                    setIsIphone(false)
                    window.open(linkForIphone)
                }}
                closeModal={() => {
                    setIsIphone(false)
                    setOpenModal(false)
                }}
                content={isIphone ? 'Premi il pulsante "CARICA" per ricaricare' : FormModalCarica()}
                button={isIphone ? null : "SALVA"}
                button2={isIphone ? "CARICA" : null}
            />
            {/* END MODAL */}
            {/* MODALE RIMBORSO*/}
            <DialogCustom
                size={"md"}
                title={"Richiedi rimborso"}
                fullScreen={false}
                open={openModalRimborso}
                handleClose={() => {
                    setErrors([])
                    api
                        .post("/users/" + count.profile.id + "/borsellino/refunded", {
                            user_id: count.profile.id,
                            amount: importRichiesto,
                            accountholder: intestatario,
                            iban: iban,
                        })
                        .then((res) => {
                            if (res.data?.errors?.wallet?.length) {
                                setErrors(res.data?.errors?.wallet)
                            } else {
                                setOpenModalRimborso(false);
                                setOpenSuccess(true);
                                setReload(!reload)
                            }
                        })
                        .catch((err) => console.log(err));
                }}
                closeModal={() => setOpenModalRimborso(false)}
                content={<FormModalRimborso errors={errors} setErrors={setErrors} importRichiesto={importRichiesto} setImportRichiesto={setImportRichiesto} iban={iban} setIban={setIban} intestatario={intestatario} setIntestatario={setIntestatario} />}
                button={"SALVA"}
            />
            {/* END MODAL */}
            {/* MODALE TERMINI*/}
            <DialogCustom
                size={"md"}
                title={"Termini e Condizioni"}
                fullScreen={false}
                open={openModalTerms}
                handleClose={() => {
                    api
                        .post("/users/borsellino/enable-column", {
                            id_user: count.profile.id,
                            column: "enabled_e_wallet",
                            enable: 1,
                        })
                        .then((res) => {
                            api
                                .post("/users/borsellino/enable-column", {
                                    id_user: count.profile.id,
                                    column: "accept_terms_ewallet",
                                    enable: 1,
                                })
                                .then((res) => {
                                    setOpenModalTerms(false);
                                    setActiveBorsellino(true);
                                    setOpenSuccess(true);
                                })
                                .catch((err) => console.log(err));
                        })
                        .catch((err) => console.log(err));
                }}
                handleClose2={() => {
                    api
                        .post("/users/borsellino/enable-column", {
                            id_user: count.profile.id,
                            column: "enabled_e_wallet",
                            enable: 0,
                        })
                        .then((res) => {
                            api
                                .post("/users/borsellino/enable-column", {
                                    id_user: count.profile.id,
                                    column: "accept_terms_ewallet",
                                    enable: 0,
                                })
                                .then((res) => {
                                    setOpenModalTerms(false);
                                    setActiveBorsellino(false);
                                    setOpenSuccess(true);
                                })
                                .catch((err) => console.log(err));
                        })
                        .catch((err) => console.log(err));
                }}
                closeModal={() => setOpenModalTerms(false)}
                content={CondizioniTermini()}
                button={"ACCETTA"}
                button2={"RIFIUTA"}
            />
            {/* END TERMINI */}
            {/* MODALE AVVISO*/}
            <DialogCustom
                size={"md"}
                title={"Avviso"}
                fullScreen={false}
                open={openModalAlert}
                handleClose={() => {
                    setOpenModalAlert(false);
                }}
                closeModal={() => setOpenModalAlert(false)}
                content={
                    <div className="text-lg">
                        Per poter disattivare il borsellino elettronico, il saldo deve
                        essere pari a 0
                    </div>
                }
                button={"CHIUDI"}
            />
            {/* END AVVISO */}
            <SnackbarComponent open={openSuccess} setOpen={setOpenSuccess} />
            <FooterTop />
            <FooterDown />
        </div>
    );
};

const mapState = (state) => ({
    count: state.count,
});

export default connect(mapState)(BorsellinoElettronico);
